






































































































































































































































































































































































































































































import Vue, { PropOptions } from 'vue'

import { statistics } from '@/store/event/helpers'
import { WeatherStationAggregatedData } from '@/store/weatherstation/definitions'
import Loader from '@/components/ui/Loader/Loader.vue'
import Button from '@/components/ui/Button/Button.vue'
import { EventBySessionsWithStationsStatistic } from '@/store/event/definitions'
import { DefaultSettingCompareSession } from './definitions'
import { DateTime } from 'luxon'

export default Vue.extend({
  name: 'compare-sessions',
  components: {
    'ui-loader': Loader,
    'ui-button': Button
  },
  props: {
    eventSessionWeatherStat: {
      type: Array,
      default: () => ([])
    } as PropOptions<EventBySessionsWithStationsStatistic[]>,
    defaultSelected: {
      type: Object,
      default: () => ({
        defaultSessionA: null,
        defaultStationA: null,
        defaultSessionB: null,
        defaultStationB: null
      })
    } as PropOptions<DefaultSettingCompareSession>,
    series: {
      type: Array,
      required: true
    },
    loading: Boolean,
    loadingCompletion: Number,
    title: {
      type: String,
      default: 'Compare sessions'
    },
    displayTitle: {
      type: Boolean,
      default: true
    },
    maximumTimeLive: {
      type: String
    }
  },
  data () {
    return {
      statistics: statistics,
      selectedSessionA: this.defaultSelected.defaultSessionA,
      selectedStationA: this.defaultSelected.defaultStationA || 'all',
      selectedSessionB: this.defaultSelected.defaultSessionB,
      selectedStationB: this.defaultSelected.defaultStationB || 'all',
      mobileStatus: 'difference', // value or difference
      openIndicator: {}
    }
  },
  computed: {
    seriesWithMinMax (): Record<string, string[]> {
      return this.series.reduce((acc, serie) => {
        const indicator = serie.match(/[A-Z]\w+/)
        if (!['sumRainIntensity', 'mainWindDirection'].includes(serie)) {
          acc[indicator] = [`avg${indicator}`, `min${indicator}`, `max${indicator}`]
        } else if (serie === 'sumRainIntensity') {
          acc[indicator] = [`sum${indicator}`, `min${indicator}`, `max${indicator}`]
        } else {
          acc[indicator] = [serie]
        }
        return acc
      }, {})
    },
    isDisplayedNBRainfall (): boolean {
      if (this.isDataNotExistForEvent) {
        return false
      }
      return this.series.some(serie => serie === 'sumRainIntensity')
    },
    dataToDisplay () {
      return this.elemToCompare(this.seriesWithMinMax)
    },
    isSelectEventNotExist (): boolean {
      return this.eventSessionWeatherStat.length === 0 || (!this.selectedSessionA && !this.selectedSessionB)
    },
    isDataNotExistForEvent (): boolean {
      return this.dataToDisplay === {} || Object.keys(this.dataToDisplay).length === 0
    },
    eventSession () {
      // Event with all session containing all stat and if is in future
      return this.eventSessionWeatherStat.map(event => {
        const currentEvent = event.sessions.map(session => {
          if (this.maximumTimeLive) {
            const delta = DateTime.fromFormat(this.maximumTimeLive.toString(), 'yyyyMMddHHmmss').valueOf() + (5 * 60 * 1000)
            return { ...session, isInFuture: DateTime.fromFormat(session.end.toString(), 'yyyyMMddHHmmss').valueOf() > delta }
          }
          return { ...session, isInFuture: false }
        })
        return { ...event, sessions: currentEvent }
      })
    }
  },
  methods: {
    /**
     * Return stations defined for a session (event + session)
     * The session must be defined like this : `$eventId--$sessionString`
     * where $eventId is the id of the event
     * and $sessionString is the string of the session (FP1, FP2, ...)
     */
    getStationsByYearAndSession (selectedSession: string): string[] {
      const [eventId, eventString] = selectedSession.split('--')
      if (!eventId) return []
      const event = this.eventSessionWeatherStat.find(event => event.id === +eventId)
      if (!event) return []
      const session = event.sessions.find(session => session.labelDisplay === eventString)
      if (!session) return []
      return session.stations.map(station => station.idStation)
    },
    getPeriodOfTimeByYearAndSession (selectedSession: string): string {
      const [eventId, eventString] = selectedSession.split('--')
      if (!eventId) return null
      const event = this.eventSessionWeatherStat.find(event => event.id === +eventId)
      if (!event) return null
      const session = event.sessions.find(session => session.labelDisplay === eventString)
      if (!session) return null
      return session.period
    },
    getDataToCompare (selectedSession: string, selectedStation: string|number): WeatherStationAggregatedData {
      if (!selectedSession) return null
      const [eventId, eventString] = selectedSession.split('--')
      if (!eventId) return null
      const event = this.eventSessionWeatherStat.find(event => event.id === +eventId)
      if (!event) return null
      const session = event.sessions.find(session => session.labelDisplay === eventString)
      if (!session) return null
      return session.stations.find(station => station.idStation === selectedStation)
    },
    computeDiffToCompare (statisticKey: string, leftValue: number|null, rightValue: number|null): { classIcon: string; value: string } {
      if (statisticKey !== 'mainWindDirection') {
        // Case If one of the numbers is null
        // we must test if the value is null specifically (because Javascript)
        if (rightValue === null || leftValue === null) {
          return { classIcon: null, value: '/' }
        }
        let diff: number|string = rightValue - leftValue
        // Case If difference is null
        if (diff === 0) {
          return { classIcon: null, value: 'same' }
        }
        // Compute specific round on the number
        if ([
          // air temp
          'minAirTemp',
          'maxAirTemp',
          'avgAirTemp',
          // track temp
          'minTrackTemp',
          'maxTrackTemp',
          'avgTrackTemp',
          // pressure
          'minPressure',
          'maxPressure',
          'avgPressure',
          // wind speed
          'minWindSpeed',
          'maxWindSpeed',
          'avgWindSpeed',
          // wind gust
          'minWindGust',
          'maxWindGust',
          'avgWindGust',
          // rain
          'minRainIntensity',
          'maxRainIntensity'
        ].includes(statisticKey)) {
          diff = Math.round(diff * 10) / 10
        } else if ([
          'minHumidity',
          'maxHumidity',
          'avgHumidity'
        ].includes(statisticKey)) {
          diff = Math.round(diff)
        } else {
          // For sumRainIntensity
          diff = diff.toFixed(2)
        }
        if (diff > 0) {
          return {
            classIcon: 'icon-fa-trend-up trend-up',
            value: `+${diff} ${this.statistics[statisticKey].unity}`
          }
        } else {
          return {
            classIcon: 'icon-fa-trend-down trend-down',
            value: `${diff} ${this.statistics[statisticKey].unity}`
          }
        }
      } else {
        return { classIcon: null, value: '/' }
      }
    },
    elemToCompare (series: Record<string, string[]>): {[key: string]: number|string[]|{classIcon: string; value: string}} {
      const dataToCompare = {}
      const leftStation = this.getDataToCompare(this.selectedSessionA, this.selectedStationA)
      const rightStation = this.getDataToCompare(this.selectedSessionB, this.selectedStationB)
      if (leftStation && rightStation) {
        Object.entries(series).forEach(([key, value]) => {
          dataToCompare[key] = {}
          value.forEach(indicator => {
            const diff = this.computeDiffToCompare(indicator, leftStation[indicator], rightStation[indicator])
            dataToCompare[key][indicator] = [diff, leftStation[indicator], rightStation[indicator]]
          })
        })
      }
      return dataToCompare
    },
    toggleMobileStatus () {
      this.mobileStatus = (this.mobileStatus === 'value' ? 'difference' : 'value')
    },
    getSessionDisplay (selectedSession) {
      if (!selectedSession) return null
      const [eventId, eventString] = selectedSession.split('--')
      if (!eventId) return null
      const event = this.eventSessionWeatherStat.find(event => event.id === +eventId)
      if (!event) return null
      const session = event.sessions.find(session => session.labelDisplay === eventString)
      return `${event.year} ${session.labelDisplay}`
    },
    toggleOpenIndicator (key: string, indicator = ''): void{
      if (this.isMinMaxExist(indicator)) Vue.set(this.openIndicator, key, !this.openIndicator[key])
    },
    isMinMaxExist (indicator: string): boolean {
      return indicator !== 'mainWindDirection'
    }
  },
  watch: {
    defaultSelected () {
      this.selectedSessionA = this.defaultSelected.defaultSessionA
      this.selectedStationA = this.defaultSelected.defaultStationA || 'all'
      this.selectedSessionB = this.defaultSelected.defaultSessionB
      this.selectedStationB = this.defaultSelected.defaultStationB || 'all'
    }
  }
})
