























































import Vue from 'vue'
import { getRects } from '@/components/data/common/helpers'

// N.B. The value of the transform is different from the other graphs because it uses a Bezier curve

export default Vue.extend({
  name: 'histogram',
  props: {
    graphHeight: Number,
    columnWidth: Number,
    circleRadius: Number,
    offsetWidth: Number,
    offsetHeight: Number,
    mainColor: String,
    notes: Array,
    width: String,
    maxWidth: String,
    isWithMinMargin: Boolean
  },
  data: () => ({
    paths: {
      main: []
    }
  }),
  computed: {
    graphWidth () {
      return this.columnWidth * this.notes.length
    }
  },
  methods: {
    buildLine () {
      this.paths = {
        ...getRects(
          this.graphHeight,
          this.notes,
          this.columnWidth,
          this.circleRadius
        )
      }
    }
  },
  watch: {
    graphWidth () {
      this.buildLine()
    },
    notes: {
      deep: true,
      immediate: true,
      handler () {
        this.buildLine()
      }
    }
  }
})

