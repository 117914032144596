import { DefaultSettingCompareSession } from '@/components/data/weatherstation/CompareSessions/definitions'
import { EventBySessionsWithStationsStatistic } from '@/store/event/definitions'

function caseOne (defaultData: DefaultSettingCompareSession, sessionsWithStat: EventBySessionsWithStationsStatistic[]): DefaultSettingCompareSession {
  const currentEvent = sessionsWithStat[0]
  const race = currentEvent.sessions.find(session => session.labelDisplay === 'Race')
  const qualif = currentEvent.sessions.find(session => session.labelDisplay === 'Q')
  // Check if data exist
  if ((!race || !qualif) || (race && race.stations.length === 0) || (qualif && qualif.stations.length === 0)) {
    return defaultData
  }

  // Update default only if stations containing data
  defaultData.defaultSessionA = currentEvent.sessions.some(session => session.labelDisplay === 'Race') ? currentEvent.id + '--Race' : null
  defaultData.defaultSessionB = currentEvent.sessions.some(session => session.labelDisplay === 'Q') ? currentEvent.id + '--Q' : null
  return defaultData
}

function caseMultiple (defaultData: DefaultSettingCompareSession, eventWithSessions: EventBySessionsWithStationsStatistic[]): DefaultSettingCompareSession {
  // Shallow copy to sort specifically sessionsWithStat in this function
  const data = [...eventWithSessions]
  const [leftData, rightData] = data.sort((a, b) => b.timestampStart - a.timestampStart)
  const raceA = leftData.sessions.find(session => session.labelDisplay === 'Race')
  const raceB = rightData.sessions.find(session => session.labelDisplay === 'Race')
  // Check if data exist
  if (!raceA || (raceA && raceA.stations.length === 0)) {
    data.splice(0, 1)
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return selectionSessions(defaultData, data)
  }
  // Check if data exist
  if (!raceB || (raceB && raceB.stations.length === 0)) {
    data.splice(1, 1)
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return selectionSessions(defaultData, data)
  }
  defaultData.defaultSessionA = raceA ? leftData.id + '--Race' : null
  defaultData.defaultSessionB = raceB ? rightData.id + '--Race' : null
  return defaultData
}

export function selectionSessions (defaultData, sessionsWithStat) {
  if (sessionsWithStat?.length > 1) {
    return caseMultiple(defaultData, sessionsWithStat)
  }
  if (sessionsWithStat?.length === 1) {
    return caseOne(defaultData, sessionsWithStat)
  }
  // Cas Aucune Session
  return defaultData
}
